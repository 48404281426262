<template>
    <div class="instant-sound col-12 text-center">
        <div class="play-btn">
            <button v-on:click="togglePlayPause"><font-awesome-icon v-bind:icon="playPause" /> ({{ shortcut }})</button>
        </div>
        <div class="row title">
            <div class="col-12 h6 text-truncate">{{ title }}</div>
        </div>
        <div class="row progress-bar">
            <progress class="col-12" ref="progress" max="100" value="0"></progress>
        </div>
        <div class="row h4 timer-bar">
            <div class="col-6"><strong>{{ sCurrentTime }}</strong><small>.{{ sCurrentTimeD }}</small></div>
            <div class="col-6"><strong>- {{ sRemainingTime }}</strong><small>.{{ sRemainingTimeD }}</small></div>
        </div>
        <div class="row transport-btn">
            <button v-on:click="stopIt" class="col-1"><font-awesome-icon icon="stop" /></button>
            <input type="range" min="0.0" max="1.0" step="0.05" ref="volume" v-on:input="volChange" class="col-4">
            <div v-bind:class="{active: playLoop}" class="led col-3" v-on:click="togglePlayLoop">Loop</div>
            <div v-bind:class="{active: killLoop}" class="led col-3" v-on:click="toggleKillLoop">L-Kill</div>
            <button v-on:click="eject" class="col-1"><font-awesome-icon icon="eject" /></button>

        </div>
        <div class="row dropzone">
            <div
                v-if="!soundfile"
                v-on:click="chooseSound"
                v-on:drop="drop"
                v-bind:class="{droppable: dragover}"
                v-on:dragenter="dragenter"
                v-on:dragleave="dragleave"
                v-on:dragover.prevent
                class="overlay col-12">
                drop me a file or click me to choose one
            </div>
        </div>
        <input ref="input" type="file" name="soundfile" id="soundfile" style="display: none;" v-on:change="loadSound">
        <audio ref="audio"
            v-bind:src="soundfile"
            v-bind:loop="playLoop"
            v-on:loadeddata="initTimers"
            v-on:timeupdate="updateTimer"
            v-on:ended="stopIt"
        ></audio>
        <div v-if="false" class="setup">
            <span class="">...</span>
            <ul>
                <li><a v-on:click="chooseSound">Load sound...</a></li>
                <li><a v-on:click="editShortcut()">Edit shortcut...</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import { bus } from '../main'
export default {
  name: 'InstantSound',
  props: {
    shortcut: null
  },
  data: function() {
      return{
        soundfile: "",
        title: "-",
        totalTime: 0,
        sTotalTime: "--:--:--.-",
        sCurrentTime: "--:--:--",
        sRemainingTime: "--:--:--",
        sCurrentTimeD: "-",
        sRemainingTimeD: "-",
        playLoop: false,
        killLoop: false,
        dragover: false,
        playPause: "play",
        volume: 1.0,
      }
  },
  created (){
    bus.$on('killLoop', () => {
      if(this.playLoop == true) this.stopIt();
    })
    bus.$on('keyTrig', (data) => {
        console.log('keyTrig ev received : ' + data);
        if(this.shortcut != null && this.soundfile != "" && data == this.shortcut) this.togglePlayPause();
    })
  },
  methods:{
    chooseSound: function() {
        this.$refs.input.click();
    },
    loadSound(event) {
        this.handleFile(event.target.files[0])
    },
    initTimers: function() {
        this.totalTime = this.$refs.audio.duration
        this.sTotalTime = this.sec2hms(this.totalTime);
        this.updateTimer()
    },
    updateTimer: function() {
        if(this.soundfile!=""){
            var t = this.sec2hms(this.totalTime - this.$refs.audio.currentTime)
            this.sRemainingTime = `${this.pad(t.h, 2)}:${this.pad(t.m, 2)}:${this.pad(t.s, 2)}`;
            this.sRemainingTimeD = `${t.d}`;
            
            t = this.sec2hms(this.$refs.audio.currentTime)
            this.sCurrentTime = `${this.pad(t.h, 2)}:${this.pad(t.m, 2)}:${this.pad(t.s, 2)}`;
            this.sCurrentTimeD = `${t.d}`;
            
            this.$refs.progress.value = 100 * this.$refs.audio.currentTime / this.totalTime;
        }
    },
    sec2hms: function(secs) {
        var ints = Math.floor(secs)
        var h = (ints - (ints % 3600))/3600
        var m = (ints - (3600 * h) - (ints % 60))/60
        var s = ints - 3600 * h - 60 * m
        var d = Math.floor((secs - ints)*10);
        return({h: h, m: m, s: s, d: d});
    },
    toggleTimeMode: function() {
        console.log("changing time mode");
        this.timeMode = ! this.timeMode
        this.updateTimer()
    },
    pad: function(number, length) {
   
        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    },
    handleFile: function(f){
        this.soundfile = window.URL.createObjectURL(f)
        this.title = f.name
        this.dragover = false;
    },
    dragenter: function(e){
        e.stopPropagation();
        e.preventDefault();
        this.dragover = true;
    },
    dragleave: function(e) {
        e.stopPropagation();
        e.preventDefault();
        this.dragover = false;
    },
    drop: function(e) {
        e.stopPropagation();
        e.preventDefault();
        const dt = e.dataTransfer;
        this.handleFile(dt.files[0]);
    },
    togglePlayPause: function() {
        if(this.playPause == "play"){
            this.playIt()
            if(this.killLoop==true) bus.$emit('killLoop');
        } else {
            this.pauseIt()
        }
    },
    playIt: function() {
        this.playPause = "pause"
        this.$refs.audio.play()
    },
    pauseIt: function() {
        this.playPause = "play"
        this.$refs.audio.pause()
    },
    stopIt: function() {
        this.playPause = "play"
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
    },
    eject: function() {
        window.URL.revokeObjectURL(this.soundfile);
        this.soundfile = "";
        this.title = "-";
        this.totalTime = 0.0;
        this.sTotalTime = "--:--:--.-";
        this.sCurrentTime = "--:--:--<small>.-</small>";
        this.sRemainingTime = "--:--:--<small>.-</small>";
    },
    togglePlayLoop: function(){
        this.playLoop = ! this.playLoop
    },
    toggleKillLoop: function() {
        this.killLoop = ! this.killLoop
    },
    volChange: function() {
        console.log("volume changed : "+this.$refs.volume.value)
        this.$refs.audio.volume = this.$refs.volume.value;
    },
    key: function(e){
        console.log("key : "+e.key)
    }
  },
}
</script>

<style scoped>
.instant-sound {
    position: relative;
    background: #333;
    color: #ccc;
    clear: both;
    padding: 0px;
}
.led {
    background-color: #333;
    font-weight: bold;
    color: black;
    padding: 0.2em;
    border: solid black 1px;
    border-radius: 3px;
}
.active {
    color: #0F3;
}
.overlay {
    background: #000;
    opacity: 0.7;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    padding-top: 1em;
    right: 0px;
}
.title{
    height: 1.5em;
}
progress {
    height: 0.5em;
    padding: 0px
}
.timer-bar {
    height: 1em;
}
.transport-btn {
    height: 1.5em;
}
.transport-btn input {
    line-height: 1.5em;
    padding: 0;
}
.play-btn button {
    height: 6em;
    width: 6em;
    float: left;
}
.droppable {
    background-color: #393;
}
</style>