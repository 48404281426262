import Vue from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEject, faPlay, faPause, faStop, faLink, faUnlink, faPlusSquare  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add([faEject, faPlay, faPause, faStop, faLink, faUnlink, faPlusSquare])

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

export const bus = new Vue();
new Vue({
  render: h => h(App),
}).$mount('#app')
