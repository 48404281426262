<template>
  <div id="app" class="container-fluid" v-on:keydown="key">
    <div class="row" style="background-color: #333;">
      <div class="col-6">
        <ListPlayer />
      </div>
      <div class="col-6" >
        <div class="row">
          <InstantSound v-for="n in 9" :shortcut="n" :key="n" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import { bus } from './main'
import InstantSound from './components/InstantSound.vue'
import ListPlayer from './components/ListPlayer.vue'

export default {
  name: 'App',
  components: {
    //HelloWorld,
    InstantSound,
    ListPlayer
  },
  methods: {
      key: function(event) {
        console.log('keyTrig ev sent : ' + event.key);
        bus.$emit('keyTrig',event.key)
      }
  },
  created() {
    window.addEventListener("keypress", e => {
      this.key(e);
    });
    window.addEventListener("beforeunload", e => {
      var message = "Do you REALLY want to exit this beautiful tool ?";
      e = e || window.event;
      if (e) {
        e.returnValue = message;
      }
      return message
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  background-color: #000;
  overscroll-behavior-y: contain;

}
html {
  background: #000;
}
</style>
