<template>
    <div class="list-player">
        <div>
            <div class="play-btn">
                <button v-on:click="togglePlayPause"><font-awesome-icon v-bind:icon="playPause" /></button>
            </div>
            <div class="row title">
                <div class="col-12 h6 text-truncate">{{ title }}</div>
            </div>
            <div class="row progress-bar">
                <progress class="col-12" ref="progress" max="100" value="0"></progress>
            </div>
            <div class="row h4 timer-bar">
                <div class="col-6"><strong>{{ sCurrentTime }}</strong><small>.{{ sCurrentTimeD }}</small></div>
                <div class="col-6"><strong>- {{ sRemainingTime }}</strong><small>.{{ sRemainingTimeD }}</small></div>
            </div>
        </div>
        <div class="list">
            <h3>Playlist</h3>
            <span v-if="tracks.length==0">Empty. Click <font-awesome-icon
                v-on:click="chooseSound"
                v-on:drop="drop"
                v-on:dragenter="dragenter"
                v-on:dragleave="dragleave"
                v-on:dragover.prevent
                v-bind:icon="[ 'fas', 'plus-square']"/> below or drop a sound file on it</span>
            <ol>
                <li draggable v-for="(track, index) in tracks"
                    :key="index"
                    v-on:click="cueFile(index)"
                    v-on:dragstart="handleDragStart"
                    v-on:dragenter="handleDragEnter"
                    v-on:dragleave="handleDragLeave"
                    v-on:drop="handleDrop"
                    v-on:dragover.prevent="handleDragOver"
                    v-bind:data-index="index"
                    v-bind:class="{cued:loadedTrackIndex==index}">
                    <span v-if="false" class="badge bg-primary"
                        v-on:click.stop="toggleChainMode">
                        <font-awesome-icon icon="link" v-if="track.chain"/>
                        <font-awesome-icon icon="unlink" v-if="!track.chain"/>
                    </span>{{ track.title }}
                </li>
            </ol>
            <input ref="input" type="file" name="soundfile" id="soundfile" style="display: none;" v-on:change="loadSound">
            <font-awesome-icon
                v-on:click="chooseSound"
                v-on:drop="drop"
                v-on:dragenter="dragenter"
                v-on:dragleave="dragleave"
                v-on:dragover.prevent
                v-bind:icon="[ 'fas', 'plus-square']"
                size="4x" />
            <audio ref="audio"
                v-bind:src="soundfile"
                v-on:loadeddata="initTimers"
                v-on:timeupdate="updateTimer"
                v-on:ended="cueNext"
            ></audio>
        </div>
    </div>
</template>

<script>
import { bus } from '../main'

export default {
    name: "ListPlayer",
    props: {
        //concurrentPlayer: 1,
    },
    data: () => {
        return{
            tracks: [],
            loadedTrackIndex: -1,
            soundfile: "",
            title: "-",
            totalTime: 0,
            sTotalTime: "--:--:--.-",
            sCurrentTime: "--:--:--",
            sRemainingTime: "--:--:--",
            sCurrentTimeD: "-",
            sRemainingTimeD: "-",
            playPause: "play",
            volume: 1.0,
            dragged: -1,
        }
    },
    methods: {
        initTimers: function() {
            this.totalTime = this.$refs.audio.duration
            this.sTotalTime = this.sec2hms(this.totalTime);
            this.updateTimer()
        },
        updateTimer: function() {
            if(this.soundfile!="null"){
                var t = this.sec2hms(this.totalTime - this.$refs.audio.currentTime)
                this.sRemainingTime = `${this.pad(t.h, 2)}:${this.pad(t.m, 2)}:${this.pad(t.s, 2)}`;
                this.sRemainingTimeD = `${t.d}`;
                
                t = this.sec2hms(this.$refs.audio.currentTime)
                this.sCurrentTime = `${this.pad(t.h, 2)}:${this.pad(t.m, 2)}:${this.pad(t.s, 2)}`;
                this.sCurrentTimeD = `${t.d}`;
                
                this.$refs.progress.value = 100 * this.$refs.audio.currentTime / this.totalTime;
            }
        },
        sec2hms: function(secs) {
            var ints = Math.floor(secs)
            var h = (ints - (ints % 3600))/3600
            var m = (ints - (3600 * h) - (ints % 60))/60
            var s = ints - 3600 * h - 60 * m
            var d = Math.floor((secs - ints)*10);
            return({h: h, m: m, s: s, d: d});
        },
        toggleTimeMode: function() {
            console.log("changing time mode");
            this.timeMode = ! this.timeMode
            this.updateTimer()
        },
        pad: function(number, length) {
    
            var str = '' + number;
            while (str.length < length) {
                str = '0' + str;
            }
            return str;
        },
        togglePlayPause: function() {
            if(this.playPause == "play"){
                this.playIt()
                if(this.killLoop==true) bus.$emit('killLoop');
            } else {
                this.pauseIt()
            }
        },
        playIt: function() {
            this.playPause = "pause"
            this.$refs.audio.play()
        },
        pauseIt: function() {
            this.playPause = "play"
            this.$refs.audio.pause()
        },
        stopIt: function() {
            this.playPause = "play"
            this.$refs.audio.pause();
            this.$refs.audio.currentTime = 0;
        },
        eject: function() {
            window.URL.revokeObjectURL(this.soundfile);
            this.soundfile = "";
            this.title = "-";
            this.totalTime = 0.0;
            this.sTotalTime = "--:--:--.-";
            this.sCurrentTime = "--:--:--<small>.-</small>";
            this.sRemainingTime = "--:--:--<small>.-</small>";
        },
        handleFile: function(f){
            var track = {
                soundfile: window.URL.createObjectURL(f),
                title: f.name,
                chain: false,
                hover: false,
            }
            this.tracks.push(track);
            if(this.loadedTrackIndex == -1) this.cueFile(0);
            this.dragover = false;
        },
        cueFile: function(index) {
            if(this.soundfile != "") this.stopIt();
            this.loadedTrackIndex = index;
            this.soundfile = this.tracks[index].soundfile;
            this.$refs.audio.load();
            this.title = this.tracks[index].title;
        },
        toggleChainMode: function(event) {
            var index = event.target.dataset.index;
            this.tracks[index].chain = !this.tracks[index].chain;
        },
        cueNext: function() {
            if(this.loadedTrackIndex == this.tracks.length) {
                // We are at the last track
                this.stopIt();
            } else {
                var newIndex = this.loadedTrackIndex + 1;
                this.cueFile(newIndex);
                if(this.tracks[newIndex].chain) this.playIt();
            }
        },
        dragenter: function(e){
            e.stopPropagation();
            e.preventDefault();
            this.dragover = true;
        },
        dragleave: function(e) {
            e.stopPropagation();
            e.preventDefault();
            this.dragover = false;
        },
        drop: function(e) {
            e.stopPropagation();
            e.preventDefault();
            const dt = e.dataTransfer;
            this.handleFile(dt.files[0]);
        },
        chooseSound: function() {
            this.$refs.input.click();
        },
        loadSound: function(event) {
            this.handleFile(event.target.files[0])
        },

        // Drag and drop to reorder playlist

        handleDragStart: function (event) {
            this.dragged = event.target.dataset.index;
            console.log("dragging track index "+ this.dragged );
        },
        handleDragEnter: function(event) {
            var index = event.target.dataset.index;
            
            this.tracks[index].hover = true;
            
            var newList = [...this.tracks];
            var item = newList[this.dragged];
            newList.splice(this.dragged, 1);
            newList.splice(index, 0, item);
            this.dragged = index;
            this.tracks = newList;
        },
        handleDragLeave: function(event) {
            var index = event.target.dataset.index;
            this.tracks[index].hover = false;
        },
        handleDragOver: function(event) {
            event.dataTransfer.dropEffect = "move";
        },
        handleDrop: function(event) {
            var index = event.target.dataset.index;
            this.tracks[index].hover = false;
        }
    }
}
</script>
<style scoped>
.list-player {
    position: relative;
    background: #333;
    color: #ccc;
    clear: both;
    padding: 0px;
}
.led {
    background-color: #333;
    font-weight: bold;
    color: black;
    padding: 0.2em;
    border: solid black 1px;
    border-radius: 3px;
}
.active {
    color: #0F3;
}
.title{
    height: 1.5em;
}
progress {
    height: 0.5em;
    padding: 0px
}
.timer-bar {
    height: 1em;
}
.transport-btn {
    height: 1.5em;
}
.transport-btn input {
    line-height: 1.5em;
    padding: 0;
}
.play-btn button {
    height: 6em;
    width: 6em;
    float: left;
}
.droppable {
    background-color: #393;
}
.list {
    clear: both;
}
.cued {
    background-color: #666;
}
</style>